<template>
  <div class="h-screen bg-gray-100">
    <TransitionFade>
      <div class="flex flex-col justify-center items-center h-full" v-if="show">
        <div class="px-5 md:px-10 xs:p-0 mx-auto w-full">
          <div class="w-full flex flex-col lg:flex-row justify-center items-center">
            <div v-if="showCreate" class="flex w-2/4 sm:rounded-lg shadow md:max-w-lg bg-white" :class="storeOK ? 'bg-gray-100 shadow-none' : 'bg-white'">
              <div class="w-full py-8 px-6 md:px-8 space-y-8">
                <div class="space-y-8" v-if="!storeOK">
                  <h1 class="text-left text-xl text-gray-800 font-medium uppercase">{{ t("createProject.title", {}, { locale: $store.state.Langs.selected.lang }) }}</h1>
                  <!-- <div>
                                    <Alert :message="t('sub_welcome',{},{locale: $store.state.Langs.selected.lang})"/>
                                </div> -->
                </div>

                <Form @submit="handleSubmit" :validation-schema="projectSchema" class="space-y-8">
                  <div v-if="!storeOK">
                    <div class="mb-3">
                      <label class="font-semibold text-sm text-gray-600 pb-1 block">{{ t("createProject.name", {}, { locale: $store.state.Langs.selected.lang }) }}</label>
                      <Field type="text" name="name" id="name" class="dl-input" v-model="newProject.Name" />
                      <TransitionFade>
                        <ErrorMessage as="div" name="name" v-slot="{ message }">
                          <div class="alert alert-error px-2 mt-2 text-sm font-semibold">
                            <div class="flex-1">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                              </svg>
                              <label>{{ message }}</label>
                            </div>
                          </div>
                        </ErrorMessage>
                      </TransitionFade>
                    </div>
                    <div class="mb-3">
                      <label class="font-semibold text-sm text-gray-600 pb-1 block">{{ t("createProject.company", {}, { locale: $store.state.Langs.selected.lang }) }}</label>
                      <Field type="text" name="company" id="company" class="dl-input" v-model="newProject.Company" />
                      <TransitionFade>
                        <ErrorMessage as="div" name="company" v-slot="{ message }">
                          <div class="alert alert-error px-2 mt-2 text-sm font-semibold">
                            <div class="flex-1">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                              </svg>
                              <label>{{ message }}</label>
                            </div>
                          </div>
                        </ErrorMessage>
                      </TransitionFade>
                    </div>
                    <TransitionFade>
                      <div class="alert alert-error px-2 text-sm font-semibold" v-if="storeError">
                        <div class="flex-1">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                          </svg>
                          <label>{{ storeErrorMessage }}</label>
                        </div>
                      </div>
                    </TransitionFade>

                    <div>
                      <label class="font-semibold text-sm text-gray-600 pb-1 block">Time Zone </label>
                      <multiselect v-model="timeZoneSelect" :options="$store.state.Utilities.timezones" searchable placeholder="Seleccionar timezone" class="input input-bordered inline-flex items-center justify-between w-full focus:outline" :canDeselect="false" :canClear="false"></multiselect>
                    </div>
                    <TransitionFade>
                      <div class="alert alert-error px-2 text-sm font-semibold mt-4" v-if="storeError">
                        <div class="flex-1">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                          </svg>
                          <label>{{ storeErrorMessage }}</label>
                        </div>
                      </div>
                    </TransitionFade>

                    <div class="mt-3 flex flex-row items-center justify-between">
                      <router-link :to="{ name: 'Projects', params: { id: $store.state.Projects.idRoute } }" class="btn btn-neutral btn-xs px-4">
                        <span>cancel</span>
                      </router-link>
                      <button type="submit" class="btn btn-primary no-animation btn-xs px-4" :class="{ loading: $store.state.Projects.loading }">
                        <span>{{ t("createProject.create_project", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                      </button>
                    </div>
                  </div>
                  <div v-if="storeOK" class="w-full py-8 px-6 md:px-8 space-y-8 flex flex-col justify-center items-center">
                    <Loader :text="t('loader.createProject', {}, { locale: $store.state.Langs.selected.lang })" />
                  </div>
                </Form>
              </div>
            </div>
            <TransitionFade>
              <div v-show="showInvite" class="flex flex-col w-2/4 sm:rounded-lg shadow md:max-w-lg bg-white overflow-hidden lg:ml-5">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-semibold text-gray-900">{{ t("invite.hi", {}, { locale: $store.state.Langs.selected.lang }) }} {{ $store.state.Auth.user.name }}!</h3>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">{{ t("invite.invitationDropdown", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ t("invite.select", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
                </div>
                <div class="px-4 py-5 sm:px-6">
                  <multiselect v-model="projectSelected" :options="projectOptions" label="name" trackBy="name" valueProp="id" searchable placeholder="Project" class="btn-select text-xs" :canDeselect="false" :canClear="false"></multiselect>
                </div>
                <div class="px-4 py-3 flex flex-col justify-center items-center">
                  <button @click="acceptProjectInvitation" :disabled="successInvite" type="submit" class="btn bg-blue-400 text-white hover:bg-blue-500 btn-xs px-4 no-animation" :class="{ loading: $store.state.Projects.loading }">
                    <span>{{ t("invite.acceptBtn", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                  </button>
                  <div v-if="successInvite" class="rounded-md bg-blue-50 my-3 p-3">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-blue-700">{{ t("invite.invitationSuccess", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionFade>
          </div>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import TransitionFade from "@/components/TransitionFade"
import { Form, Field, ErrorMessage } from "vee-validate"
import * as yup from "yup"
// import Alert from '@/components/Alert'
import Multiselect from "@vueform/multiselect"
import Loader from "@/components/UI/Loader.vue"
import axiosClient from "@/config/AxiosClient"
import { CheckCircleIcon } from "@heroicons/vue/solid"

export default {
  name: "New Project",
  components: {
    TransitionFade,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    Loader,
    CheckCircleIcon
    // Alert
  },
  data: function () {
    const projectSchema = yup.object({
      name: yup.string().required(),
      company: yup.string().required()
    })
    return {
      newProject: {
        name: "",
        company: ""
      },
      projectSchema,
      show: false,
      showInvite: false,
      showCreate: true,
      storeError: false,
      storeErrorMessage: false,
      storeOK: false,
      timeZoneSelect: "America/Argentina/Buenos_Aires",
      projectSelected: "",
      projectOptions: [],
      projectId: {},
      pending: {},
      successInvite: false
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  async mounted() {
    setTimeout(() => {
      this.show = true
    }, 50)
    this.getInvitationForUser()
  },
  methods: {
    handleSubmit(values, { resetForm }) {
      this.storeError = false
      this.storeErrorMessage = ""
      this.storeOK = false

      values.is_active = 1
      values.dataJson = {}
      values.timezone = this.timeZoneSelect

      this.$store
        .dispatch("Projects/storeProject", values)
        .then(async (res) => {
          if (res.status == 500) {
            this.$toast.error("Error, please try again ina few minutes")
          }
          if (res.status == 401) {
            this.$toast.error(`${res.data.message}`)
          }
          if (res.status == 422) {
            console.log(res.data)
            this.storeErrorMessage = Object.keys(res.data.errors)
              .map(function (errorKey) {
                return res.data.errors[errorKey].join(",")
              })
              .join(",")
            this.storeError = true
          }
          if (res.status == 201 || res.status == 200) {
            this.storeOK = true
            resetForm({
              values: {
                name: "",
                company: ""
              }
            })
            await this.$store.dispatch("Projects/getProject", res.data.data.id)
            setTimeout(() => {
              this.$router.push({
                name: "Guide",
                params: { id: res.data.data.id }
              })
            }, 900)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getInvitationForUser() {
      let res = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/invitations/user/${this.$store.state.Auth.user.id}?include=project`)
      res.data.data.forEach((option) => {
        let push = { id: option.id, name: option.project.name, is_pending: option.is_pending }
        if (option.id) push.id = option.id
        // if(option.is_pending) this.pending = option.is_pending
        // if(this.pending != 0) this.showInvite = true
        this.projectOptions.push(push)
        if (this.projectOptions.find((f) => f.is_pending == 1)) {
          this.showCreate = false
          this.showInvite = true
        }
      })
    },
    async acceptProjectInvitation() {
      let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/accept/invitation/${this.projectSelected}/${this.$store.state.Auth.user.id}`)
      if (response.status == 200 || response.status == 204) {
        this.successInvite = true
        setTimeout(() => {
          this.$router.push({
            name: "Projects",
            params: { id: "" }
          })
          this.showInvite = false
        }, 900)
      }
    }
  },
  async created() {
    await this.$store.dispatch("Utilities/timezones")
  }
}
</script>
